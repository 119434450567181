<template>
  <b-card title="Edit PCode">
    <b-row>
      <b-col cols="12">
        <b-form @submit.prevent="">

          <b-form-group
              description="PCode Name"
            >
            <label>
                Name <span style="color:red">(*)</span>
            </label>
              <b-form-input
                v-model="form.name"
                type="text"
                placeholder="Enter name"
                required
              ></b-form-input>
          </b-form-group>

          <!-- <b-form-group
              label="Industry Type"
              description="Industry Type"
            >
              <b-form-select
                id="input-delivery-type"
                v-model="form.distChan"
                placeholder="----- Select Industry Type -----"
                :options="industryTypes">
              </b-form-select>
          </b-form-group> -->
             <label for="start-input">Validity Start <span style="color:red">(*)</span></label>
            <b-input-group class="mb-3">
            <b-form-input
              @change="onDateInput"
              maxlength=10
              id="start-input"
              v-model="form.validityStartInput"
              type="text"
              placeholder="DD-MM-YYYY"
              autocomplete="off"
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="form.validityStart"
                button-only
                right
                locale="en-US"
                aria-controls="start-input"
                @input="onContext"
              ></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>

            <label for="end-input">Validity End <span style="color:red">(*)</span></label>
            <b-input-group class="mb-3">
            <b-form-input
              @change="onDateInput"
              maxlength=10
              id="end-input"
              v-model="form.validityEndInput"
              type="text"
              placeholder="DD-MM-YYYY"
              autocomplete="off"
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="form.validityEnd"
                button-only
                right
                locale="en-US"
                aria-controls="end-input"
                @input="onContext"
              ></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>

          <b-form-group
              description="Extension"
            >
            <label>Extension (Month) <span style="color:red">(*)</span></label>
              <b-form-input
                id="input-credit"
                v-model="form.extension"
                type="text"
                placeholder="Enter Extension"
                required
              ></b-form-input>
          </b-form-group>

          <b-form-group
              description="Status"
            >
            <label>Status <span style="color:red">(*)</span></label>
              <b-form-select
                v-model="form.status"
                :options="pCodeStatus">
              </b-form-select>
          </b-form-group>
        </b-form>
      </b-col>

      <!-- <b-col cols="12">
        <label>Model</label>
        <b-form-select
            v-model="selectedModel"
            placeholder="----- Select PCode To Add -----"
            :options="listItemModels">
        </b-form-select>

        <b-button @click="addToModelList" variant="outline-success" class="mt-1">Add Model</b-button>
      </b-col> -->

      <b-col cols="12" class="my-1">
        <b-form-group
              label="Model"
              description="Please make sure for each row have only one Model"
            >
        <b-form-textarea
          rows="6"
          max-rows="8"
          no-auto-shrink
          v-model="form.models"></b-form-textarea>
        </b-form-group>
        <!-- <b-table
          :items="form.models"
          :fields="fieldsModel"
          show-empty
        >
          <template #cell(actions)="row">
            <b-button variant="danger" @click="deleteFromModelList(row.index)">
              <feather-icon icon="Trash2Icon"/>
            </b-button>
          </template>
        </b-table> -->
      </b-col>

      <b-col cols="12">
        <b-button @click="doUpdatePCode" type="submit" variant="success">Update</b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { sleep } from '@/utils/utils.js'

export default {
  data() {
    return {
      
     form: {
      name: '',
      validityStartInput:"",
      validityStart: '',
      validityEndInput:"",
      validityEnd: '',
      extension: '',
      // distChan: null,
      status: 'Active',
      models: "",
     },
     industryTypes: [
     ],
     pCodeStatus: [
      { value: 'Approved', text: 'Approved' },
      { value: 'Inactive', text: 'Inactive' }
     ],
     fieldsModel: [
       { key: 'itemModel', label: 'Model' },
       { key: 'actions', label: 'Actions' },
     ],
     selectedModel: null,
    }
  },
  created(){
    this.getPCode(this.$route.params.pcode).then((data) => {
      // Data And Frontend Respresentaion Have Different Shape
      // data.models = data.models.map((item) => {
      //   return { itemModel: item };
      // });
      let convert = data.models.toString().replace(/,/g,'\n')
      
      // console.info()

      this.form = data;
      this.form.validityStart= data.validityStart.substr(0,10)
      var startTemp= this.form.validityStart.split('-');
      this.form.validityStartInput= startTemp[2]+"-"+startTemp[1]+"-"+startTemp[0]
      this.form.validityEnd= data.validityEnd.substr(0,10)
      var endTemp= this.form.validityEnd.split('-');
      this.form.validityEndInput= endTemp[2]+"-"+endTemp[1]+"-"+endTemp[0]
      
      this.form.models = convert;
      //this.onContext();
    });
  },
  mounted() {
    

    this.getItems();

    // this.getChannels().then((data) => {
    //   this.industryTypes = data.map((x) => {
    //     return {
    //       value: x._id, text: x.name
    //     }
    //   });
    // });
  },
  methods: {
    ...mapActions({
      'getChannels': 'pcode/getChannels',
      'getItems': 'pcode/getItemList',
      'getPCode': 'pcode/getPCode',
      'updatePCode': 'pcode/updatePCode',
    }),
    onDateInput(){
      //process start date
      var startTemp;
      var endTemp;
      if(this.form.validityStartInput.length==10){
        startTemp= this.form.validityStartInput.split('-');

        if(startTemp.length==3){
          this.form.validityStart=startTemp[2]+"-"+startTemp[1]+"-"+startTemp[0]
        }
      }
      if(this.form.validityEndInput.length==10){
        endTemp= this.form.validityEndInput.split('-');
        if(endTemp.length==3){
          this.form.validityEnd=endTemp[2]+"-"+endTemp[1]+"-"+endTemp[0]
        }
      }

    },
    onContext(ctx){
      var startTemp;
      var endTemp;
      this.form.validityStartInput=""
      this.form.validityEndInput="";
      if(this.form.validityStart.length==10){
        startTemp= this.form.validityStart.split('-');

        if(startTemp.length==3){
          this.form.validityStartInput=startTemp[2]+"-"+startTemp[1]+"-"+startTemp[0]
        }

      }
      if(this.form.validityEnd.length==10){
        endTemp= this.form.validityEnd.split('-');

        if(endTemp.length==3){
          this.form.validityEndInput=endTemp[2]+"-"+endTemp[1]+"-"+endTemp[0]
        }
      }
    },
    // addToModelList() {
    //   let foundIndex = this.form.models.findIndex((item) => (item.itemModel === this.selectedModel));

    //   if (foundIndex < 0) {
    //     this.form.models.push({ itemModel: this.selectedModel });
    //   }
    // },
    // deleteFromModelList(beginIndex) {
    //   this.form.models.splice(beginIndex, 1);
    // },
    doUpdatePCode() {
      let deepCopyForm = JSON.parse(JSON.stringify(this.form))
      deepCopyForm.models = deepCopyForm.models.split("\n")
      // console.info({deepCopyForm})

      // deepCopyForm.models = deepCopyForm.models.map((item) => {
      //   return item.itemModel
      // })

      this.updatePCode(deepCopyForm).then(async (res) => {
        this.$bvToast.toast(`You have successfully update a Pcode ${this.form.name}`, {
          title: "Success",
          variant: "success",
          solid: true,
        });
        await sleep(1000)
        this.$router.push({ name: "pcode" });
      }).catch((e) => {
        this.$bvToast.toast(`You have failed to update a Pcode ${this.form.name}`, {
          title: "Fail",
          variant: "danger",
          solid: true,
        });
      });
    }
  },
  computed: {
    ...mapGetters({
      itemsOptions: 'item/itemsOptions',
      listItemModels: 'pcode/toModelSelection'
    })
  }
}
</script>